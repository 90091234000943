import { CLAIM_SUB_TYPES, CLAIM_TYPES } from "@upsolve/shared";

// from Claim.ts
// can remove a lot of these fields
export type MeridianLinkCreditReportClaim = {
  id?: number;
  type?: string;
  subType?: string;
  creditor?: {
    name?: string;
  };
  subTypeOtherDescription?: string;
  tertiaryType?: string;
  accountNumber?: string;
  date?: string;
  isContingent?: boolean;
  isDisputed?: boolean;
  isUnliquidated?: boolean;
  offset?: boolean;
  value?: number;
  valuePriority?: number;
  status?: string;
  lien?: string;
  createdAt?: Date;
  updatedAt?: Date;
};
export const getDischargeableClaims = (claims: MeridianLinkCreditReportClaim[]) =>
  claims.filter((claim) => {
    // Check for non-dischargeable types
    if (
      [
        CLAIM_SUB_TYPES.DOMESTIC_SUPPORT,
        CLAIM_SUB_TYPES.EDUCATION,
        CLAIM_SUB_TYPES.GOVERNMENT,
        CLAIM_SUB_TYPES.PERSONAL_INJURY,
        CLAIM_SUB_TYPES.RESTITUTION,
        CLAIM_SUB_TYPES.AUTOMOBILE, // in the questionnaire, we check for claims that users manually associate with a vehicle. here i'm just filtering out claims of type automobile. Affects of this unclear.
      ].includes(claim.subType || "")
    ) {
      return false;
    }
    // this is also not done in the questionnaire. unclear why.
    if (claim.type === CLAIM_TYPES.SECURED) {
      return false;
    }

    return true;
  });

// for use in the "eligible debts" popup
export const getNonDischargeableClaims = (claims: MeridianLinkCreditReportClaim[]) =>
  claims.filter((claim) => {
    // Check for non-dischargeable types
    if (
      [
        CLAIM_SUB_TYPES.DOMESTIC_SUPPORT,
        CLAIM_SUB_TYPES.EDUCATION,
        CLAIM_SUB_TYPES.GOVERNMENT,
        CLAIM_SUB_TYPES.PERSONAL_INJURY,
        CLAIM_SUB_TYPES.RESTITUTION,
        CLAIM_SUB_TYPES.AUTOMOBILE,
      ].includes(claim.subType || "")
    ) {
      return true;
    }
    if (claim.type === CLAIM_TYPES.SECURED) {
      return true;
    }

    return false;
  });
