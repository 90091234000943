export const PROMPTS: Record<
  OptionName,
  {
    ELIGIBLE: string;
    INELIGIBLE: string;
    UNKNOWN?: string;
  }
> = {
  "Ch. 7 Bankruptcy": {
    ELIGIBLE: "Why might Ch. 7 Bankruptcy be a good fit for me?",
    INELIGIBLE: "Why isn't Ch. 7 Bankruptcy a good fit for me?",
    UNKNOWN: "How can I determine if Ch. 7 Bankruptcy is a good fit for me?",
  },
  "Debt Management": {
    ELIGIBLE: "Why is Debt Management a good fit for me?",
    INELIGIBLE: "Why isn't Debt Management a good fit for me?",
  },
  "Debt Negotiation": {
    ELIGIBLE: "Why is Negotiation a good fit for me?",
    INELIGIBLE: "Why isn't Negotiation a good fit for me?",
  },
  Consolidation: {
    ELIGIBLE: "Why is Debt Consolidation a good fit for me?",
    INELIGIBLE: "Why isn't Debt Consolidation a good fit for me?",
    UNKNOWN: "How can I determine if Debt Consolidation is a good fit for me?",
  },
};

export type OptionName = "Debt Management" | "Ch. 7 Bankruptcy" | "Debt Negotiation" | "Consolidation";
