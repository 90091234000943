import React, { useState } from "react";
import { Input } from "./Components";

interface DatePickerProps {
  onChange: (date: Date) => void;
}

// TODO: move state up to parent component for validation
const DatePicker: React.FC<DatePickerProps> = ({ onChange }) => {
  const [selectedDate, setSelectedDate] = useState<string>("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
    const date = new Date(event.target.value);
    if (!isNaN(date.getTime())) {
      onChange(date);
    }
  };

  return (
    <div>
      <Input type="date" value={selectedDate} onChange={handleChange} />
    </div>
  );
};

export default DatePicker;
