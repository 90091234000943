import React, { useState } from "react";
import { ModalContent } from "../modals/TriageModal";
import {
  IntroHeading,
  DescriptionCentered,
  Input,
  Button,
  QuestionHeading,
  Controls,
  DetailDescriptionCentered,
  Select,
} from "./Components";
import DatePicker from "./DatePicker";
import { TScreenProps } from "./Screens";
import styled from "styled-components";
import { STATES } from "@upsolve/shared";
import {
  MeridianLinkUserInfo,
  useMeridianLinkCreditReport,
} from "./meridianLinkCreditReport/pullMeridianLinkCreditReport";
import { findOrCreateAnonymousId } from "./useChat";
import { MeridianLinkCreditReportClaim } from "./meridianLinkCreditReport/meridianLinkCreditReportUtils";
import { theme } from "@upsolve/ui";

const StyledCreditPullRequest = styled(ModalContent)`
  border-radius: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: calc(100vh - 140px);
  box-sizing: border-box;
  overflow-y: auto;
  @media (max-height: ${theme.breakpoints[500]}) {
    max-height: initial;
  }
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .labeledInput {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .consent-container {
      display: flex;
      gap: 10px;
      align-items: center;
      .consent-checkbox-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        .consent-checkbox {
          width: 24px;
          height: 24px;
        }
      }
    }
    .location-container {
      display: flex;
      flex-direction: row;
      gap: 24px;
      @media (max-width: ${theme.breakpoints[500]}) {
        flex-direction: column;
      }
    }
  }
`;

type TCreditPullRequestProps = {
  onSubmitUserInfo: (userInfo: MeridianLinkUserInfo) => void;
  isLoading: boolean;
};

// TODO: remove unnecessary fields from fe and be. I was able to pull credit with incorrect middlename and zip, idk if those are necessaary
const CreditPullRequest = (props: TCreditPullRequestProps) => {
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    ssn: "",
    street1: "",
    city: "",
    state: "AL",
    zipcode: "",
    birthDate: "",
  });
  const [consent, setConsent] = useState(false);
  const isSSNValid = /^(?!666|000|9\d{2})\d{3}-?(?!00)\d{2}-?(?!0000)\d{4}$/.test(userInfo.ssn) && !!userInfo.ssn;

  const isSubmitDisabled =
    !userInfo.firstName ||
    !userInfo.lastName ||
    !isSSNValid ||
    !userInfo.street1 ||
    !userInfo.city ||
    !userInfo.state ||
    !userInfo.zipcode ||
    !userInfo.birthDate ||
    !consent ||
    props.isLoading;

  return (
    <StyledCreditPullRequest>
      <IntroHeading>Your information is safe with us!</IntroHeading>
      <DescriptionCentered>
        We take your privacy seriously. With your permission, we'll perform a soft pull of your credit report—this won't
        affect your score. Your information is securely encrypted and never shared without your consent.
      </DescriptionCentered>
      <div className="form-container">
        <div className="labeledInput">
          <label>First Name</label>
          <Input
            type="string"
            placeholder="John"
            onChange={(e) => setUserInfo({ ...userInfo, firstName: e.target.value })}
            value={userInfo.firstName}
            required
          />
        </div>
        <div className="labeledInput">
          <label>
            Middle Name
            <Input
              type="string"
              placeholder="Jacob"
              onChange={(e) => setUserInfo({ ...userInfo, middleName: e.target.value })}
              value={userInfo.middleName}
            />
          </label>
        </div>
        <div className="labeledInput">
          <label>Last Name</label>
          <Input
            type="string"
            placeholder="Doe"
            onChange={(e) => setUserInfo({ ...userInfo, lastName: e.target.value })}
            value={userInfo.lastName}
            required
          />
        </div>
        {/* TODO: remove dashes from input */}
        <div className="labeledInput">
          <label>Social Security Number</label>
          <Input
            invalid={!isSSNValid}
            type="string"
            placeholder="123456789"
            onChange={(e) => setUserInfo({ ...userInfo, ssn: e.target.value })}
            value={userInfo.ssn}
            required
          />
        </div>
        <div className="labeledInput">
          <label>Date of Birth</label>
          <DatePicker onChange={(date: Date) => setUserInfo({ ...userInfo, birthDate: date.toISOString() })} />
        </div>
        <div className="labeledInput">
          <label>Street Address</label>
          <Input
            type="string"
            placeholder="123 Main Street"
            onChange={(e) => {
              setUserInfo({ ...userInfo, street1: e.target.value });
            }}
            value={userInfo.street1}
          />
        </div>
        <div className="location-container">
          <div className="labeledInput">
            <label>City</label>
            <Input
              type="string"
              placeholder="San Francisco"
              onChange={(e) => setUserInfo({ ...userInfo, city: e.target.value })}
              value={userInfo.city}
            />
          </div>
          <div className="labeledInput">
            <label>
              {/* todo: improve this input styling with a custom select component */}
              State
            </label>
            <Select placeholder="Select" onChange={(e) => setUserInfo({ ...userInfo, state: e.target.value })}>
              {Object.entries(STATES).map(([abbreviation, value]) => (
                <option value={abbreviation}>{abbreviation}</option>
              ))}
            </Select>
          </div>
          <div className="labeledInput">
            <label>Zip</label>
            <Input
              type="number"
              placeholder="11111"
              onChange={(e) => setUserInfo({ ...userInfo, zipcode: e.target.value })}
              value={userInfo.zipcode}
            />
          </div>
        </div>
        <div className="consent-container">
          <div className="consent-checkbox-container">
            <Input className="consent-checkbox" type="checkbox" onChange={(e) => setConsent(e.target.checked)} />
          </div>
          <div className="consent-text">
            I/We understand that by clicking on the checkbox immediately preceding this notice, you are providing
            "written instruction" to UPSOLVE under the Fair Credit Reporting Act authorizing UPSOLVE to obtain
            information from your personal credit profile or other information from the National Credit Bureaus.
          </div>
        </div>
        <Button disabled={isSubmitDisabled} onClick={() => props.onSubmitUserInfo(userInfo)}>
          Next
        </Button>
      </div>
    </StyledCreditPullRequest>
  );
};

type TCreditReportDetailsScreenProps = {
  claims: MeridianLinkCreditReportClaim[];
  totalDebt: number;
  onSubmit: () => void;
};

export const CreditPullScreen = ({ controls, updateState }: TScreenProps) => {
  const { isLoading, data, error, fetchMeridianLinkCreditReport } = useMeridianLinkCreditReport();
  const userId = findOrCreateAnonymousId();
  const claims = data?.claims;

  // TODO: handle situation where user has no claims + loading + error
  const onSubmitUserInfo = (userInfo: MeridianLinkUserInfo) => {
    fetchMeridianLinkCreditReport(userInfo, userId);
    updateState({ zipcode: userInfo.zipcode });
  };

  if (error) {
    return (
      <CreditPullErrorScreen
        onSubmit={() => {
          updateState({ creditPullFailed: true });
          controls.onNext();
        }}
      />
    );
  }

  if (!data) {
    return <CreditPullRequest onSubmitUserInfo={onSubmitUserInfo} isLoading={isLoading} />;
  }

  const filteredClaims =
    claims?.filter((claim) => !!claim.value && claim.value > 0).sort((a, b) => (b.value || 0) - (a.value || 0)) || [];
  const totalDebt = filteredClaims?.reduce((acc, claim) => acc + (claim.value || 0), 0) || 0;
  return (
    <CreditReportDetailsScreen
      claims={filteredClaims}
      totalDebt={totalDebt}
      onSubmit={() => {
        updateState({
          unsecuredDebtEstimate: totalDebt,
          claims: filteredClaims,
          creditPullFailed: false, //(in case user retries)
        });
        controls.onNext();
      }}
    />
  );
};
const CreditPullErrorScreen = (props: { onSubmit: () => void }) => {
  return (
    <StyledCreditPullErrorScreen>
      <IntroHeading>We were unable to verify your identity</IntroHeading>
      <DescriptionCentered>
        {/* TODO: improve this copy */}
        You can give an estimate of your debt to explore debt relief options that might be a good fit for you.
      </DescriptionCentered>
      <Button onClick={props.onSubmit}>Next</Button>
    </StyledCreditPullErrorScreen>
  );
};

const StyledCreditPullErrorScreen = styled(ModalContent)``;

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 12px;
`;

const StatBox = styled.div`
  text-align: center;
  border-radius: 8px;
  background: var(--Neutral-50, #f5f5f8);
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
`;

const StatValue = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const StatLabel = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 150%; /* 18px */
  color: ${theme.colors.gray[600]};
`;

const DebtList = styled.div`
  margin: 20px 0;
  flex: 1;
  /* todo dynamic height  - use flexbox to make it dynamic */
  max-height: 280px;
  overflow: auto;
  @media (max-height: ${(p) => p.theme.breakpoints[500]}) {
    max-height: 180px;
  }
`;

const DebtItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 2px dashed ${theme.colors.white[500]};
  @media (max-height: ${(p) => p.theme.breakpoints[500]}) {
    padding: 10px;
  }
`;

const DebtInfo = styled.div``;
const DebtBalance = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
`;

const DebtName = styled.div`
  font-weight: bold;
  font-size: 14px;
  font-weight: 600;
`;

const DebtType = styled.div`
  color: ${theme.colors.white[400]};
  font-size: 12px;
`;

export const getReadableDebtType = (str: string): string => {
  return str
    .replace("_", " ")
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const CreditReportDetailsScreen = (props: TCreditReportDetailsScreenProps) => {
  return (
    <ModalContent>
      <QuestionHeading>We successfully imported your debts!</QuestionHeading>
      <DetailDescriptionCentered>
        If you notice any errors or missing information, don't worry — everything will be finalized when you choose to
        enroll in a plan.
      </DetailDescriptionCentered>

      <StatsContainer>
        <StatBox>
          <StatValue>{props.claims.length}</StatValue>
          <StatLabel>ACCOUNTS</StatLabel>
        </StatBox>
        <StatBox>
          <StatValue>${props.totalDebt.toLocaleString()}</StatValue>
          <StatLabel>TOTAL DEBT</StatLabel>
        </StatBox>
      </StatsContainer>

      <DebtList>
        {props.claims.map((claim) => {
          const claimName = claim.creditor?.name || claim.subType || claim.type;

          return (
            <DebtItem key={claim.id}>
              <DebtInfo>
                <DebtName>{claimName}</DebtName>
                <DebtType>{getReadableDebtType(claim.subType || claim.type || "")}</DebtType>
              </DebtInfo>
              <DebtBalance>${(claim.value || "0").toLocaleString()}</DebtBalance>
            </DebtItem>
          );
        })}
      </DebtList>

      <Controls>
        <Button onClick={props.onSubmit} className="action-button" data-test-label="next-button">
          Next
        </Button>
      </Controls>
    </ModalContent>
  );
};
