export const learnTagsSortedByPriority = {
  BANKRUPTCY_BASICS: "bankruptcyBasics",
  CHAPTER_7: "chapter7",
  WAGE_GARNISHMENT: "wageGarnishment",
  PROPERTY_EXEMPTIONS: "propertyExemptions",
  CARS: "cars",
  DEBTS: "debts",
  STUDENT_LOANS: "studentLoans",
  UPSOLVE: "upsolve",
  CONSUMER_RIGHTS: "consumerRights",
  TAXES: "taxes",
  CHAPTER_13: "chapter13",
  COURT: "court",
  DIVORCE: "divorce",
  CHAPTER_11: "chapter11",
  NEWS: "news",
};
