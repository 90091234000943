import { getStateFromZipcode } from "@upsolve/shared";
import { getPaymentInfo } from "./getCompoundedInterest";

// keep in sync with backend: answerUserQuery.ts
export type EligibilityResult = {
  isEligible: boolean | undefined;
  totalPayment: number;
  monthlyPayment: number;
  interest: number;
  canAfford: boolean | undefined;
  fees: number;
  monthsInRepayment: number;
  // TODO: Workshop these on figma
  notEligibleMessage?: string;
};

// Savings can also be represented as time savings.
export const getStatusQuoCompoundInterest = (unsecuredDebtEstimate: number, availableMonthlyFunds: number) => {
  return getStatusQuoRepaymentInfo(unsecuredDebtEstimate, availableMonthlyFunds).totalPayment - unsecuredDebtEstimate;
};

export const getStatusQuoRepaymentInfo = (unsecuredDebtEstimate: number, availableMonthlyFunds: number) => {
  // TODO: make 23% an export variable
  return getPaymentInfo(unsecuredDebtEstimate, 0.23, availableMonthlyFunds);
};

export const getDmpEligibilityResults = (
  hasIncome: boolean,
  unsecuredDebtEstimate: number,
  availableMonthlyFunds: number
): EligibilityResult => {
  const statusQuoTotalPayment = Math.round(
    getStatusQuoRepaymentInfo(unsecuredDebtEstimate, availableMonthlyFunds).totalPayment
  );
  const fees = Math.round(unsecuredDebtEstimate * 0.06);
  const { monthsInRepayment, totalPayment, canAfford } = getPaymentInfo(
    unsecuredDebtEstimate,
    0.08,
    availableMonthlyFunds
  );

  const isEligible =
    hasIncome &&
    unsecuredDebtEstimate > 1000 &&
    canAfford &&
    monthsInRepayment <= 60 &&
    totalPayment + fees < statusQuoTotalPayment;
  const notEligibleMessage = !hasIncome
    ? "Debt management plans are only available to people who have income."
    : unsecuredDebtEstimate <= 1000
    ? "Debt management plans are only worth it if you have more than $1000 of debt."
    : !canAfford || monthsInRepayment > 60
    ? `With your monthly payment and debt amount, you will not be able to pay off your debt with a Debt Management Plan.`
    : totalPayment + fees >= statusQuoTotalPayment
    ? "The savings from a debt management plan would be less than the fees for the plan."
    : undefined;

  const interest = Math.round(totalPayment - unsecuredDebtEstimate);
  const monthlyPayment = totalPayment / monthsInRepayment;

  return {
    notEligibleMessage,
    monthsInRepayment,
    canAfford,
    monthlyPayment,
    isEligible,
    totalPayment: totalPayment + fees,
    interest,
    fees,
  };
};

export const getConsolidationEligibilityResults = (
  unsecuredDebtEstimate: number,
  availableMonthlyFunds: number,
  hasGoodCreditScore: boolean
): EligibilityResult => {
  // https://www.bankrate.com/loans/personal-loans/average-personal-loan-rates/
  // TODO: make these variables
  const goodCreditInterestRate = 0.1243;
  const badCreditInterestRate = 0.3;
  const userInterestRate = hasGoodCreditScore ? goodCreditInterestRate : badCreditInterestRate;
  const fees = Math.round(unsecuredDebtEstimate * userInterestRate);
  const { monthsInRepayment, totalPayment, canAfford } = getPaymentInfo(
    unsecuredDebtEstimate,
    userInterestRate,
    availableMonthlyFunds
  );

  const statusQuoTotalPayment = Math.round(
    getStatusQuoRepaymentInfo(unsecuredDebtEstimate, availableMonthlyFunds).totalPayment
  );

  const savings = Math.round(statusQuoTotalPayment - totalPayment - fees);
  const interest = Math.round(totalPayment - unsecuredDebtEstimate);
  const monthlyPayment = totalPayment / monthsInRepayment;

  const { isEligible, notEligibleMessage } = (() => {
    if (savings < 1000) {
      return {
        isEligible: false,
        notEligibleMessage:
          "Consolidation won't provide significant savings over paying off your debt as you are currently doing.",
      };
    }

    if (!hasGoodCreditScore) {
      return {
        isEligible: false,
        notEligibleMessage:
          "Without a high credit score, it will be difficult to consolidate your debts into one with lower interest rates.",
      };
    }

    return { isEligible: true, notEligibleMessage: undefined };
  })();

  return {
    isEligible,
    notEligibleMessage,
    totalPayment,
    monthsInRepayment,
    monthlyPayment,
    canAfford,
    interest,
    fees,
  };
};

export const getSettlementEligibilityResults = (
  passesMeansTest: boolean,
  canRepayHalfOfDebt: boolean,
  unsecuredDebtEstimate: number
): EligibilityResult => {
  const isEligible = !passesMeansTest && canRepayHalfOfDebt;
  const notEligibleMessage = isEligible
    ? undefined
    : "Debt negotiation is only useful if you have income and significant savings you can start paying off the debt with.";

  // TODO: add disclaimer copy: Most people pay between 50-75% of current debt based on the age and type of debt.
  const settlementLumpSumPaymentEstimate = 0.75 * unsecuredDebtEstimate;

  return {
    isEligible,
    notEligibleMessage,
    totalPayment: settlementLumpSumPaymentEstimate,
    interest: 0,
    fees: 0,
    monthsInRepayment: 0,
    canAfford: isEligible,
    monthlyPayment: 0,
  };
};

export const getBankruptcyEligibilityResults = (
  passesMeansTest: boolean,
  unsecuredDebtEstimate: number,
  zipcode: string
): EligibilityResult => {
  const state = getStateFromZipcode(zipcode);
  if (!state) {
    throw new Error("Could not compute state from zipcode");
  }
  const fees = 338;
  const hasEnoughDebt = unsecuredDebtEstimate >= 10000;

  const isEligible = passesMeansTest && hasEnoughDebt ? true : undefined;

  // TODO: make two levels of elaboration, where we say more on the details screen
  // TODO: if user has <10k debt, we will skip the comparison entirely
  const notEligibleMessage = !hasEnoughDebt
    ? "Chapter 7 Bankruptcy is best used when you have more than $10,000 of debt."
    : !passesMeansTest
    ? "Request a free evaluation with a bankruptcy attorney to see if bankruptcy is right for you. "
    : undefined;

  const totalPayment = isEligible ? 338 : 2338;

  return {
    isEligible,
    notEligibleMessage,
    totalPayment,
    interest: 0,
    fees,
    monthsInRepayment: 0,
    canAfford: isEligible,
    monthlyPayment: 0,
  };
};
