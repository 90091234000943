import React from "react";
import { theme } from "@upsolve/ui";
import styled from "styled-components";
import SparklesIcon from "./images/sparkles-icon-dark.svg";

export const QuestionHeading = styled.h3`
  color: ${theme.colors.black[300]};
  font-size: 24px;
  line-height: 150%;
  font-weight: 500;
  white-space: pre-wrap;
  margin: 0 0 10px 0;
`;

export const DetailHeading = styled.h3`
  color: ${theme.colors.black[300]};
  font-size: 24px;
  line-height: 150%;
  font-weight: 500;
  white-space: pre-wrap;
  margin: 0 0 6px 0;
`;

export const DetailDescriptionCentered = styled.p`
  color: ${theme.colors.gray[500]};
  opacity: 80%;
  margin: 0 0 26px 0;
  width: 100%;
  display: block;
`;

export const IntroHeading = styled.h2`
  color: ${theme.colors.black[300]};
  text-align: center;
  font-size: 24px;
  line-height: 42px;
  font-weight: 500;
  white-space: pre-wrap;
  margin: -10px 0 6px 0;
`;

export const DescriptionCentered = styled.p`
  text-align: center;
  color: ${theme.colors.gray[500]};
  opacity: 80%;
  margin: 0 0 10px 0;
  width: 100%;
  display: block;
`;

export const IntroSmallText = styled.p`
  color: ${theme.colors.gray[500]};
  opacity: 80%;
  margin: 0;
  text-align: center;
  width: 100%;
  display: block;
  a {
    text-decoration: underline;
  }
`;

export const Description = styled.p`
  color: ${theme.colors.gray[500]};
  opacity: 80%;
  margin-bottom: 10px;
  width: 100%;
  display: block;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-size: 24px;

  span {
    animation: blink 3s infinite normal;
  }

  span:nth-child(1) {
    animation-delay: 0s;
  }
  span:nth-child(2) {
    animation-delay: 0.5s;
  }
  span:nth-child(3) {
    animation-delay: 1s;
  }

  @keyframes blink {
    0%,
    30%,
    100% {
      opacity: 0;
    }
    10%,
    30% {
      opacity: 1;
    }
  }
`;
export const Select = styled.select`
  padding: 4px 16px;
  width: 570px;
  height: 57px;
  font-size: 16px;
  background: #ffffff;
  border: 1px solid #d0d0d4;
  border-radius: 12px;
  ::placeholder {
    color: ${theme.colors.gray[500]};
    opacity: 0.5;
  }
`;

type InputProps = {
  invalid?: boolean;
};
export const Input = styled.input<InputProps>`
  padding: 4px 16px;
  width: 570px;
  height: 57px;
  font-size: 16px;
  background: #ffffff;
  border-radius: 12px;
  border: ${(props) => (!props.invalid ? "1px solid #d0d0d4" : `1px solid ${theme.colors.red[500]}`)};
  ::placeholder {
    color: ${theme.colors.gray[500]};
    opacity: 0.5;
  }
`;

const StyledCurrencyInput = styled.div`
  position: relative;
  input {
    outline: 0;
    position: relative;
    z-index: 1;
    padding-left: 1.4em;
  }
`;

const CurrencySymbol = styled.span`
  position: absolute;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%);
  left: 0.75em;
  font-size: 13px;
`;

export const CurrencyInput: React.FC<any> = (props) => {
  return (
    <StyledCurrencyInput className="content-node__input">
      <CurrencySymbol>$</CurrencySymbol>
      <Input type="text" inputMode="numeric" pattern="[0-9]*" {...props} data-hj-allow data-test-label={props.label} />
    </StyledCurrencyInput>
  );
};

interface ButtonProps {
  secondary?: boolean;
}

export const Button = styled.button<ButtonProps>`
  width: 100%;
  height: 50px;
  font-weight: 600;
  background: ${({ secondary, disabled }) => (secondary ? "#ECEFFD" : disabled ? "#CACACC" : theme.colors.brand[500])};
  border-radius: 12px;
  border: 0px;
  color: ${({ secondary, disabled }) => (secondary ? theme.colors.brand[300] : disabled ? "#7F7F80" : "white")};
  cursor: ${({ disabled }) => (!disabled ? "pointer" : "")};
  &:hover {
    background: ${({ secondary, disabled }) =>
      secondary ? theme.colors.brand[700] : disabled ? "#CACACC" : theme.colors.brand[300]};
  }
`;

const AIPromptButtonStyle = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  svg {
    margin-right: 8px;
    flex-shrink: 0;
  }
  width: 100%;
  padding: 12px 20px;
  min-height: 50px;
  font-weight: 600;
  background: #eceffd;
  border-radius: 12px;
  border: 0px;
  color: ${theme.colors.brand[300]};
  cursor: pointer;
  &:hover {
    background: ${theme.colors.brand[700]};
  }
`;

type TAIPromptButtonProps = {
  displayText: string;
  onClick: () => void;
};

export const AIPromptButton = (props: TAIPromptButtonProps) => {
  return (
    <AIPromptButtonStyle onClick={props.onClick}>
      <SparklesIcon />
      <span>{props.displayText}</span>
    </AIPromptButtonStyle>
  );
};

const StyledErrorBox = styled.span<{ $visible: boolean }>`
  visibility: ${(props) => (props.$visible ? "visible" : "hidden")};
  font-size: 12px;
  color: ${theme.colors.red[500]};
  margin-top: 5px;
  margin-left: 3px;
`;

export const ErrorBox = (props: { hasError: boolean; children: React.ReactNode }) => {
  return <StyledErrorBox $visible={props.hasError}>{props.children}</StyledErrorBox>;
};

export const Controls = styled.div`
  margin-top: 2.2em;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
