import { theme } from "@upsolve/ui";
import React from "react";
import styled from "styled-components";
import ThumbsUp from "./images/thumbs-up.svg";
import ThumbsDown from "./images/thumbs-down.svg";
import QuestionMark from "./QuestionMark.svg";
import { TOptionInfo } from "./ResultsComparison";
import { formatMonths } from "./formatMonths";
import { DetailHeading, DetailDescriptionCentered, AIPromptButton } from "./Components";
import { addMonths, format } from "date-fns";
import { PROMPTS } from "./constants";

type TStatusQuoInfo = {
  interest: number;
  totalPayment: number;
  monthlyPayment: number;
  monthsInRepayment: number;
  canAfford: boolean;
};

export const DetailCard = (props: {
  optionInfo: TOptionInfo;
  unsecuredDebtEstimate: number;
  availableMonthlyFunds: number;
  statusQuoInfo: TStatusQuoInfo;
  onClickPromptButton: (messageText: string) => void;
  onClickBack: () => void;
  onOpenChat: () => void;
}) => {
  return (
    <StyledDetailCardContainer>
      <StyledDetailCard isEligible={props.optionInfo.isEligible}>
        <button className="back" onClick={props.onClickBack} data-test-label="back-button">
          Back
        </button>
        <div style={{ marginTop: "-80px" }}>
          <StyledDetailCardThumbContainer>
            {props.optionInfo.isEligible ? (
              <ThumbsUp />
            ) : props.optionInfo.isEligible !== false ? (
              <QuestionMark />
            ) : (
              <ThumbsDown />
            )}
          </StyledDetailCardThumbContainer>
          <div className="title_section section">
            <DetailHeading>{props.optionInfo.title}</DetailHeading>
            <DetailDescriptionCentered>{props.optionInfo.description}</DetailDescriptionCentered>

            {props.optionInfo.isEligible !== false && (
              <FinancialInfoContainer>
                <div className="items">
                  <div className="financial-item">
                    <p>${Math.round(props.optionInfo.paymentInformation.value).toLocaleString()}</p>
                    <strong>{props.optionInfo.paymentInformation.fieldName}</strong>
                  </div>
                  <div className="financial-item">
                    <p>{format(addMonths(new Date(), props.optionInfo.debtTimelineBreakdown.duration), "MMM y")}</p>
                    <strong>Debt-Free Date</strong>
                  </div>
                </div>
              </FinancialInfoContainer>
            )}

            {props.optionInfo.notEligibleMessage && (
              <IneligibleMessageBox>{props.optionInfo.notEligibleMessage}</IneligibleMessageBox>
            )}

            <div className="actions_section">
              <div className="button-container">
                <AIPromptButton
                  displayText={
                    props.optionInfo.isEligible
                      ? PROMPTS[props.optionInfo.optionName].ELIGIBLE
                      : (props.optionInfo.isEligible === undefined && PROMPTS[props.optionInfo.optionName]?.UNKNOWN) ||
                        PROMPTS[props.optionInfo.optionName].INELIGIBLE
                  }
                  onClick={() =>
                    props.onClickPromptButton(
                      props.optionInfo.isEligible
                        ? PROMPTS[props.optionInfo.optionName].ELIGIBLE
                        : (props.optionInfo.isEligible === undefined &&
                            PROMPTS[props.optionInfo.optionName]?.UNKNOWN) ||
                            PROMPTS[props.optionInfo.optionName].INELIGIBLE
                    )
                  }
                />
                {props.optionInfo.actions}
                <div className="disclaimer">
                  The estimates above reflect common interest rates, standard timelines, and the debts you've shared
                  with us.
                </div>
              </div>
            </div>
          </div>
        </div>

        {props.optionInfo.isEligible === true && (
          <div className="savings_section section">
            <SectionTitle>COST BREAKDOWN</SectionTitle>
            <SavingsTable>
              <div className="content">
                <div className="row">
                  <div className="description">
                    {/* change to "Eligible balance" for credit pull version */}
                    <div className="label">Debt balance</div>
                    <div className="amount">${Math.round(props.unsecuredDebtEstimate).toLocaleString()}</div>
                  </div>
                </div>

                {props.optionInfo.savingsBreakdown.map((savingsBreakdownRow, index) => (
                  <div
                    className={`row ${index === props.optionInfo.savingsBreakdown.length - 1 ? "last-math-row" : ""}`}
                    key={savingsBreakdownRow.item?.toString()}
                  >
                    <div className="description">
                      <div className="label">{savingsBreakdownRow.item}</div>
                      <div className="amount">{savingsBreakdownRow.value}</div>
                    </div>
                  </div>
                ))}
                {/* TODO: undashed line */}

                <div className="row timeline">
                  <div className="description">
                    {/* TODO: include option name */}
                    <div className="label">Estimated Timeline</div>
                    <div className="amount">{formatMonths(props.optionInfo.debtTimelineBreakdown.duration)}</div>
                  </div>
                </div>

                <div className="row total">
                  <div className="description">
                    <div className="label">Total payment</div>
                    <div className="amount">${Math.round(props.optionInfo.totalPayment).toLocaleString()}</div>
                  </div>
                </div>
              </div>
            </SavingsTable>

            {/* <Small style={{ color: theme.colors.gray[500], marginTop: "8px" }}>
              *Calculations are estimated based on the information you provided. Upsolve does not provide legal advice,
              and you should confirm eligibility with a lawyer.
            </Small> */}

            {props.optionInfo.savingsQuestions.map((question) => (
              <AIPromptButton displayText={question} onClick={() => props.onClickPromptButton(question)} />
            ))}
          </div>
        )}
        <div className="good_for_section section">
          <b className="section-title">Common Questions</b>
          {props.optionInfo.faqQuestions.map((faqQuestion) => (
            <AIPromptButton displayText={faqQuestion} onClick={() => props.onClickPromptButton(faqQuestion)} />
          ))}
        </div>
        <div className="actions_section">
          <div className="button-container-bottom">{props.optionInfo.actions}</div>
        </div>
      </StyledDetailCard>
    </StyledDetailCardContainer>
  );
};
const StyledDetailCardContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
`;
const StyledDetailCard = styled.div<{ isEligible: boolean | undefined }>`
  display: flex;
  flex-direction: column;
  text-align: center;
  flex: 1;
  padding: 8px;
  max-width: 600px;
  gap: 24px;
  .back {
    color: black;
    background-color: ${theme.colors.white[700]};
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 12px;
    padding: 10px 24px;
    cursor: pointer;
    width: 20vw;
    max-width: 100px;
    margin-top: 30px;
    z-index: 99;
  }
  .section {
    padding: 32px 24px;
    @media (min-width: ${(props) => props.theme.breakpoints[500]}) {
      padding: 40px;
    }
    background: #ffffff;
    box-shadow: 0px 24px 24px rgba(0, 0, 0, 0.05), 0px 16px 24px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: 14px;
    .section-title {
      text-transform: uppercase;
      opacity: 80%;
      color: ${theme.colors.gray[500]};
      font-weight: 700;
    }
  }
  .title_section {
    border-radius: 8px;
    padding-top: 40px;
    padding-bottom: 24px;
    box-sizing: border-box;
    display: flex;
    gap: 0px;
    flex-direction: column;
    text-align: center;
    .disclaimer {
      font-size: 11px;
      line-height: 150%;
      color: ${theme.colors.gray[700]};
      opacity: 0.7;
    }
  }
  .savings_section {
    display: flex;
    flex-direction: column;
    .estimated_debt {
      display: flex;
      flex-direction: column;
    }
    .title {
      margin-bottom: 8px;
    }
    .payment {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;
    }
    .disclaimer {
      font-size: 11px;
      opacity: 0.7;
    }
  }
  .good_for_section {
  }
  .affordability_section {
  }
  .actions_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    .button-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .button-container-bottom {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 60px;
    }
  }

  .bottom_padding {
    padding: 20px;
  }

  .red {
    color: ${theme.colors.red[300]};
  }
  .green {
    color: ${theme.colors.green[300]};
  }
  .gray {
    color: ${theme.colors.gray[500]};
  }
  .info-row {
    display: flex;
    justify-content: space-between;
  }
  .info-row + .info-row {
    border-top: 1px dashed ${(props) => props.theme.colors.white[300]};
    margin-top: 5px;
    padding-top: 5px;
  }
`;

const StyledDetailCardThumbContainer = styled.div`
  position: relative;
  top: 38px;
`;

const IneligibleMessageBox = styled.div`
  font-size: 14px;
  text-align: start;
  width: 100%;
  margin-bottom: 1em;
  background-color: #f5f5f8;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .title {
    text-align: center;
  }
`;

const FinancialInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  .items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 32px;
    gap: 22px;
    width: 100%;
    box-sizing: border-box;
    @media (max-width: 768px) {
      padding: 0;
      gap: 12px;
    }
  }

  .financial-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;

    p {
      font-size: 20px;
      font-weight: bold;
      color: #333;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }

    strong {
      font-size: 12px;
      font-weight: 300;
      color: #666;

      @media (max-width: 768px) {
        margin-top: -4px;
        font-size: 10px;
      }
    }
  }
`;

const SectionTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #6b7280;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const SavingsTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px dotted ${theme.colors.white[100]};

    &:last-child {
      border-bottom: none;
    }

    .description {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-size: 14px;
      @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
        font-size: 12px;
      }

      .label {
        color: #4c4c4d;
        font-weight: 500;
      }

      .amount {
        color: #4c4c4d;
        font-weight: 700;
      }
    }
  }

  .total {
    border-bottom: none;
  }
  .last-math-row {
    border-bottom: 1px solid ${theme.colors.white[100]};
  }
`;
