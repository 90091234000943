import axios from "axios";
import { useState } from "react";
import { MeridianLinkCreditReportClaim } from "./meridianLinkCreditReportUtils";

export type MeridianLinkUserInfo = {
  ssn: string;
  firstName: string;
  middleName: string;
  lastName: string;
  street1: string;
  city: string;
  state: string;
  zipcode: string;
  birthDate: string;
};
export const useMeridianLinkCreditReport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<{ claims: MeridianLinkCreditReportClaim[] } | null>(null);

  const fetchMeridianLinkCreditReport = async (userInfo: MeridianLinkUserInfo, userId: string) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${UPSOLVE_API_URL}/v1/debtAdvisor/pullCreditReport`, {
        userInfo,
        userId,
      });
      setData(response.data);
      setIsLoading(false);
      setError(null);
    } catch (error) {
      setError(error as Error);
      setIsLoading(false);
    }
  };

  return { isLoading, data, error, fetchMeridianLinkCreditReport };
};

// TODO: use useQuery?
